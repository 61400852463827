//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  components: {
    XModal: () => import("@/components/XModal"),
  },
  data() {
    return {
      teamNum: null,
    };
  },
  methods: {
    /**
     * 确认
     */
    onConfirm() {
      if (!this.teamNum) {
        this.$message({
          showClose: true,
          message: "请输入团队号",
          type: "warning",
        });
        return;
      }
      this.$refs.modal.loadBtn(true);
      const data = {
        teamCode: this.teamNum,
      };
      this.$http
        .post("/Teams/InvitedOrApply/ApplyTeam.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.$message({
              showClose: true,
              message: "申请发送成功！",
              type: "success",
            });
            this.$modal.hide("joinTeam");
            this.$emit("success", resp.data.teamId);
          }
        })
        .finally(() => this.$refs.modal.loadBtn(false));
    },
    /**
     * 初始化数据
     */
    closed() {
      Object.assign(this.$data, this.$options.data());
    },
  },
};
